import { jsxs as b, jsx as c } from "react/jsx-runtime";
import { useState as z, useRef as k, useEffect as u, useMemo as E } from "react";
import { ScrollContainer as S } from "@vgno/core";
import { classnames as j, fetchVideos as V } from "@vgno/utils";
import { getStoriesOverlay as C } from "@vgno/video";
import './assets/VideoCarousel.css';const D = "_video_1mxmj_1", M = "_highlight_1mxmj_14", O = "_image_1mxmj_21", W = "_title_1mxmj_28", A = "_label_1mxmj_37", o = {
  video: D,
  highlight: M,
  image: O,
  title: W,
  label: A
}, q = ({
  className: y,
  format: m = "vertical",
  isIOS: x = !1,
  playlistId: d,
  title: h,
  videos: N,
  ...R
}) => {
  const [n, I] = z(N), f = k(null), p = k([]);
  u(() => {
    if (!d) return;
    (async () => {
      const r = await V({ playlistId: d }), s = r == null ? void 0 : r.map((t) => ({
        ...t,
        url: `https://www.vg.no/stories/${t.id}?playlistId=${d}`
      }));
      I(s);
    })();
  }, [d]);
  const a = E(() => m === "vertical" ? n == null ? void 0 : n.filter(
    (e) => e.status === "active" && e.additional.metadata.aspectRatio === "0.56"
  ) : n, [m, n]);
  u(() => {
    if (!a) return;
    const e = new IntersectionObserver(([r]) => {
      var t;
      if (!r.isIntersecting)
        return;
      e.disconnect();
      const s = a.map((i) => i.url).filter((i) => i.startsWith("https://www.vg.no/stories/"));
      (t = C()) == null || t.preloadItems(s);
    });
    return e.observe(f.current), () => e.disconnect();
  }, [a]), u(() => {
    const e = new IntersectionObserver(
      (r) => {
        r.forEach((s) => {
          const t = s.target;
          t instanceof HTMLImageElement || (s.isIntersecting && t.dataset.src && (t.src = t.dataset.src, delete t.dataset.src), s.isIntersecting && s.intersectionRatio >= 0.95 ? t.play() : t.pause());
        });
      },
      {
        rootMargin: "0px",
        threshold: [0, 0.95]
      }
    );
    return p.current.forEach((r) => {
      r && e.observe(r);
    }), () => e.disconnect();
  }, [a]);
  const g = (e, r) => {
    p.current[r] = e;
  };
  return /* @__PURE__ */ b(
    "section",
    {
      className: j("font-inter", y),
      "data-track-element-type": "Vertical Video Carousel",
      "data-track-primary-position": 1,
      ...R,
      ref: f,
      children: [
        h && /* @__PURE__ */ c(
          "h2",
          {
            className: j(
              "font-weight-semi-bold title-large",
              o.label
            ),
            children: h
          }
        ),
        /* @__PURE__ */ c(S, { scrollBy: (m === "horizontal" ? 300 : 200) + 16, children: a == null ? void 0 : a.map((e, r) => {
          var _, w;
          const { preview_vivi_category: s } = e.additional.metadata, t = e.imageAsset, i = ((_ = t == null ? void 0 : t.urls) == null ? void 0 : _[4].url) || ((w = t == null ? void 0 : t.urls) == null ? void 0 : w[0].url), v = s || t == null ? void 0 : t.urls.map(({ url: l, width: $ }) => `${l} ${$}w`).join(", ");
          return /* @__PURE__ */ c("li", { className: o.video, children: /* @__PURE__ */ b(
            "a",
            {
              "data-track-secondary-position": r + 1,
              "data-track-id": `teaser:${e.id}`,
              "data-track-name": e.title,
              "data-track-target-newsroom": e.provider,
              "data-track-image-url": i,
              "data-track-click-intent": "Watch",
              "data-track-impression": !0,
              href: e.url,
              children: [
                x || !s ? /* @__PURE__ */ c(
                  "img",
                  {
                    alt: "",
                    className: o.image,
                    fetchpriority: "low",
                    loading: "lazy",
                    ref: (l) => g(l, r),
                    src: s ?? i,
                    ...v && {
                      sizes: m === "horizontal" ? "300" : "200",
                      srcSet: v
                    }
                  }
                ) : /* @__PURE__ */ c(
                  "video",
                  {
                    className: o.image,
                    "data-src": s,
                    disableRemotePlayback: !0,
                    loop: !0,
                    muted: !0,
                    playsInline: !0,
                    poster: i,
                    ref: (l) => g(l, r)
                  }
                ),
                /* @__PURE__ */ c("h3", { className: o.title, children: /* @__PURE__ */ c("span", { className: o.highlight, children: e.title }) })
              ]
            }
          ) }, e.id);
        }) })
      ]
    }
  );
};
export {
  q as default
};
